import { loadScript } from "./utility.service"

const manageJsOnElementsExistCondition = () => {
  const signupModalMain = document.getElementById("signupModalMain")
  if(signupModalMain) {
    const signupJsEl = document.querySelector(
      `script[src="${window.pageData.signupLoadJs}"]`
    )
    !signupJsEl && loadScript({
      src: window.pageData.signupLoadJs,
      type: "text/javascript",
    })
  }
}

export default manageJsOnElementsExistCondition